/*Cards*/
/*Widget Color*/
/*btn*/
/*Cards*/
/*Widget Color*/
/*btn*/
.callHistory-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  width: 100%;
  margin: 1px 5px 5px 5px; }

.callHistoryInvoiceLink {
  color: blue;
  cursor: pointer; }

/*Cards*/
/*Widget Color*/
/*btn*/
.equipment-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
/*--------------------*/
/*WidgetIcon.tsx      */
.widgetText {
  font-size: .7em;
  text-align: center;
  font-weight: bolder;
  display: block !important;
  padding-top: 13px;
  color: black; }

.widgetBadge {
  position: absolute;
  right: 4px;
  top: -3px;
  font-size: .7em; }

.widgetContainer {
  margin: 5px;
  text-align: center;
  width: 90px; }

.widgetIcon {
  position: relative; }

.widgetImage {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.6); }

/*.widgetImage {
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.6);
}*/
/*--------------------*/
/*MainWidgetScreen    */
.widgetInfoAreaDetail {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 4px;
  border-radius: 7px;
  border: solid lightgray 1px;
  background-color: white; }

.widgetInfoAreaDetailLine1 {
  font-size: 0.8em; }

.widgetInfoAreaDetailLine2 {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 0.9em; }

div .widgetMainContainer {
  padding-top: 20px;
  background-color: white;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  display: flex !important; }

div .widgetInfoArea {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

/*Cards*/
/*Widget Color*/
/*btn*/
.headerstyle {
  background-color: #343a40; }

.headerCompanyName {
  font-size: 1.3em;
  color: white;
  padding: 10px 10px 10px 20px; }

.headerMenu {
  padding-top: 5px;
  text-align: right; }

/* .headerText {
        font-size: 1em;
    }

    .headerTextSmall {
        font-size: .7em;
    }

    .headerUpperRow {
        border-bottom: 1px solid white;
        padding: 5px;
    }*/
body .logInPositionDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh; }

body .logInCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 4px;
  border-radius: 7px;
  border: solid lightgray 1px;
  background-color: white;
  width: 310px; }

/*Cards*/
/*Widget Color*/
/*btn*/
.miscBackIcon {
  font-size: 1.3em;
  color: #343a40; }

body .swLabel {
  font-size: 0.7em !important; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .service-contract-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .service-contact-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .ARPicker-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
.recommendation-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .service-contract-renew-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin-top: 10px; }

body .service-contract-renew-btndiv {
  margin-top: 10px;
  text-align: center; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .service-contract-maint-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
.customerRequest-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
.pendingAppointment-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

/*Cards*/
/*Widget Color*/
/*btn*/
.customerFeedback-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

.customerFeedback-response-card {
  margin-left: 10px;
  padding: 10px;
  background-color: lightyellow; }

/*Cards*/
/*Widget Color*/
/*btn*/
body .customer_request-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin-top: 10px; }

body .customer_request-message-card {
  color: black;
  background: #fffad2; }

body .customer_request-btn-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  text-align: center; }

body .customer_request-btn-noSelected {
  background-color: rgba(40, 167, 69, 0.5);
  border-color: rgba(40, 167, 69, 0.5); }

/*Cards*/
/*Widget Color*/
/*btn*/
.additionalUserList-card {
  color: black;
  background: rgba(236, 238, 244, 0.2);
  margin: 5px;
  width: 100%; }

.additionalUserList-newBtn {
  margin: 15px;
  text-align: center; }

body {
  font: 100% Helvetica, sans-serif;
  color: black; }
  body .right-align {
    text-align: right; }
  body .center-align {
    text-align: center; }
  body .text-bold {
    font-weight: bold; }
  body .text-small {
    font-size: 0.8em; }
  body .loading-overlay {
    padding: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.row-center-align {
  justify-content: center; }

.top-screen-button {
  margin: 15px;
  text-align: center; }
